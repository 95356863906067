@media (max-width: 600px) {

    body {
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;
    }

.sidebar {
display: none;
}

.sidebar-small {

    display: none;

}

.sidebar-right {
    background-color: #ffffff;
    height: auto;
    position: relative !important;
    right: 0px;
    top: 0px;
    width: 100% !important;
    top: auto !important;
    border-left: 0px solid #dee2e6 !important;
    z-index: 100;

    .p-card .p-card-body {
        
    }
}

.main {
padding: 40px 20px;
&.close {

margin: 0px;

}
}

.navbar-top {
    box-shadow: @shadow;
}

.sidebar.open {
    width: 0px;
}

#main.open .navbar-top {
    width: 100%;
}


.hidden-mobile {
    display: none;
    visibility: hidden;
}

.show-mobile {
    display: inline;
    visibility: visible;
}

#main.close {
    margin-left: 0px;
}

.welcome-banner {
    padding-bottom:20px;
    img {
        display: none;
        visibility: hidden;
    }
}

#main {
    transition: margin-left 0.1s;
    padding: 66px 0px 0px 0px;
    margin-left: 70px;
    margin: 0px;
}

.tab-menu {
    padding: 10px 0px 0px 0px;
}
.top-main-wrapper {
    background-color: @white;
    margin-bottom: 30px;
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;

    h3 {
        color: @ness_color_2;
    }
}

#main .main-inner {
    padding: 0px 15px 30px 15px;
}

#main.open {
    margin-left: 0px;
}


.navbar-top {

    .openbtn {
        font-size: 22px;
        cursor: pointer;
        color: @ness_color_2;
        width: 80px;
        height: 70px;
        border: none;
        text-align: center;
        line-height: 70px;
        float: left;
        margin-left: 0px;
        border-right: 0px solid #ccc;
        i {
          color: @ness_color_2;
        }
      }


      .logo-wrapper {
        float: left;
        margin-left: 0px;
        top: 6px;
        width: 110px;
        position: relative;
        height: 60px;
        padding-right: 0px;
        border-right: 0px solid #dee2e6;
        font-size: 26px;
        background: url(src/assets/images/logo.svg) no-repeat left center;
        background-size: 80%;
        font-weight: bold;
        margin-left: 0px;
        img {
          height: 30px;
          line-height: 60px;
          position: relative;
          top: 15px;
        }
    }

}


.p-card .p-card-body {
    padding: 10px 10px !important;
}

.p-tabview .p-tabview-panels {
padding: 10px 0px !important;
}

.p-datatable .p-datatable-tbody > tr > td:first-child {
    padding: 10px 5px !important;
    min-width: 100px !important;
 
}

.p-datatable .p-datatable-tbody > tr {
    border-bottom: 1px solid #ccc !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 10px 5px !important;
    min-height: 40px;
}

.p-datatable .p-datatable-tbody > tr > td:last-child {
    padding: 10px 5px !important;
 
}

.p-sidebar-right {
    height: 100%;
    right: 0;
    top: 0;
    width: 100% !important;
    min-width: 100% !important;
}

.p-toast {
    min-width: 340px !important;
    width: 340px !important;
}

.filter-options {
    padding-bottom: 0px;
    border-bottom: 1px solid #dee2e6;
}

.p-dialog {

    min-width: 300px !important;

}
.p-paginator-current {
    display: none !important;
    visibility: hidden;
}
.navbar-top .user-menu {
    list-style: none;
    float: right;
    margin-right: 20px;
    margin-top: 5px;
}


.responsive-tab-view .p-tabview-nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  
    li {
      flex: 0 0 auto;
    }
  }


  #main .main-inner.with-sidebar {
    margin-right: 0px !important;
}

.top-main-wrapper.with-sidebar {
    margin-right: 0px !important;
}

.top-main-wrapper {

    .p-button-label {
        display: none;
    }

    .p-button-icon-left {
        margin: 0px;
    }

}

.sidebar-inside {
    display: none;
    visibility: hidden;
}




.fc-other-month {
    background-color: fade(@ness_color_3, 6%) !important;
  }
  
  .fc-day-grid-event {
    margin: 5px;
    padding: 0 1px;
  }
  
  .fc-day-number {
    padding-left: 5px;
  }
  
  .fc .fc-view-container .fc-event {
    background: fade(@ness_color_3, 90%);
    box-shadow: @shadow;
    border: 1px solid #dee2e6;
    border-left: 0px solid @ness_color_3 !important;
    color: @white;
    text-align: center;
    padding: 0px;
    padding-top: 3px;
    hr {
      border-color: @ness_color_3 !important;
    }
    i {
      font-size: 13px;
    }
    .fc-title {
      a {
        color: @ness_color_3 !important;
      }
    }
  }
  
  .fc .fc-view-container .fc-event .event-time {
    background: transparent;
    border-radius: 0px;
    padding: 3px;
    margin-bottom: 0px;
    font-size: 9px;
  }
  
  .fc .fc-view-container .fc-event .event-client-name {
      display: none;
      visibility: hidden;
  }
  
  .fc .fc-toolbar .fc-button {
    color: @ness_color_2;
    background: transparent;
    border: 1px solid @ness_color_2;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }
  
  .fc-icon {
    display: block;
    // Icon base
    &:after {
      display: inline-block;
      font-family: "FontAwesome";
      line-height: 1;
      font-size: 14px;
      vertical-align: middle;
      position: relative;
      top: -1px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }



.p-sidebar-right {
    height: 100%;
    right: 0;
    top: 0;
    max-width: 500px !important;
  }

}



@media (min-width: 1200px) {

    .login-box .login-box-inner {
      
    }

}

@media (min-width: 1400px) {

    .login-box .login-box-inner {
       
    }
    
    }